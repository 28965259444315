import React from 'react'
import Link from 'gatsby-plugin-transition-link'
import gsap from 'gsap'

export default ({ to, label, tiny = true }) => (
  <Link 
    preventScrollJump
    exit={{
      length: 0.6,
      trigger: ({ exit, node }) => {
        gsap.to(node, {
          opacity: 0,
          duration: 0.6,
          ease: 'circ.out',
          onComplete: () => window.scrollTo(0, 0)
        });
      },
    }}
    entry={{
      length: 0.8,
      trigger: ({ exit, node }) => {
        gsap.fromTo(node, {
          opacity: 0
        }, {
          opacity: 1,
          duration: 0.8,
          delay: 0.8,
          ease: 'power4.out'
        });
      },
    }} to={to} className={ tiny ? 'o-title-tiny' : ''}>{label}</Link>
)

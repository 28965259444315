import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import TransitionLink from "../components/transition-link"

const Footer = () => {
  const { allContentfulExternalLink } = useStaticQuery(
    graphql`
      query {
        allContentfulExternalLink(sort: {fields: updatedAt, order: DESC}) {
          edges {
            node {
              id
              url
              label
              mobileLabel
            }
          }
        }
      }
    `
  )
  return (
    <nav role="navigation" className="c-footer l-wrapper">
      <ul className="c-footer__list">
        {allContentfulExternalLink.edges.map((link, index) => {
          const node = link.node;
          return <li className="c-footer__item" key={index}>
            <a href={node.url} className="o-title-tiny"><span className="u-desktop-only">{node.label}</span><span className="u-mobile-only">{node.mobileLabel}</span></a>
          </li>
        })}
        <li className="c-footer__item">
          <TransitionLink to="/labs" label="Labs" />
        </li>
        <li className="c-footer__item">
          <strong className="o-title-tiny">© Lotte Bijlsma {new Date().getFullYear()}</strong>
        </li>
      </ul>
    </nav>
  )
}
export default Footer
import React from 'react'
import { Link } from 'gatsby'
import Navigation from './navigation'
import Footer from './footer'
import { Helmet } from 'react-helmet'
import '../styles/main.scss';

class Template extends React.Component {
  render() {
    const {
      children,
      classes = [],
      title = '',
      description = 'Digital Product Designer who turns problems into beautifully crafted solutions'
    } = this.props;

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    classes.push('l-main-wrapper')

    return (
      <React.Fragment>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <link rel="alternate icon" type="image/png" href="/favicon.png" />

          {/* OpenGraph tags */}
          <meta property="og:url" content="https://lottebijlsma.com/" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content='https://lotte.mamboleoo.be/thumbnail.jpg' />

          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content="@BijlsmaLotte" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content='https://lotte.mamboleoo.be/thumbnail.jpg' />
          <meta name="twitter:site" content="https://lottebijlsma.com/" />
        </Helmet>
        <div className={classes.join(' ')}>
          <Navigation />
          {children}
          <Footer />
        </div>
      </React.Fragment>
    )
  }
}

export default Template

import React, { useState } from 'react'
import TransitionLink from "../components/transition-link"

class Navigation extends React.Component {
  // const [navIsBlack, setNavColor] = useState(true);

  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      navIsBlack: true
    };
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('updateNavColor', this._onUpdateNav);
  }

  componentWillUnmount () {
    this._isMounted = false;
    window.removeEventListener('updateNavColor', this._onUpdateNav);
  }

  onUpdateNav (param) {
    if (!this._isMounted) return;
    let navIsBlack = true;
    if (param.detail && param.detail.isBlack) {
      navIsBlack = param.detail.isBlack;
    } else {
      navIsBlack = false;
    }
    this.setState({
      navIsBlack: navIsBlack
    });
  }

  render() {
    this._onUpdateNav = this.onUpdateNav.bind(this);

    return (
      <nav role="navigation" className={`c-nav l-wrapper ${this.state.navIsBlack ? '' : 'is-white'}`}>
        <ul className="c-nav__list">
          <li className="c-nav__item">
          <TransitionLink to="/" label="Work" />
          </li>
          <li className="c-nav__item">
            <TransitionLink to="/about" label="About" />
          </li>
          <li className="c-nav__item">
            <TransitionLink to="/" label="Lotte Bijlsma" className="o-title-tiny" />
          </li>
        </ul>
      </nav>
    )
  }
}

export default Navigation
